/*
Flaticon icon font: Flaticon
*/

$font-Flaticon-path: "fonts/";
$font-Flaticon-businessman: "\f100";
$font-Flaticon-dollar: "\f101";
$font-Flaticon-network: "\f102";
$font-Flaticon-business: "\f103";

@font-face {
	font-family: "Flaticon";
	src: url("#{$font-Flaticon-path}flaticon.eot");
	src: url("#{$font-Flaticon-path}flaticon.eot?#iefix") format("embedded-opentype"),
		 url("#{$font-Flaticon-path}flaticon.woff") format("woff"),
		 url("#{$font-Flaticon-path}flaticon.ttf") format("truetype"),
		 url("#{$font-Flaticon-path}flaticon.svg#Flaticon") format("svg");
	font-weight: normal;
	font-style: normal;
}

@media screen and (-webkit-min-device-pixel-ratio:0) {
	@font-face {
		font-family: "Flaticon";
		src: url("#{$font-Flaticon-path}flaticon.svg#Flaticon") format("svg");
	}
}

.fi:before{
	display: inline-block;
	font-family: "Flaticon";
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	line-height: 1;
	text-decoration: inherit;
	text-rendering: optimizeLegibility;
	text-transform: none;
	-moz-osx-font-smoothing: grayscale;
	-webkit-font-smoothing: antialiased;
	font-smoothing: antialiased;
}

.flaticon-businessman:before {
	content: $font-Flaticon-businessman;
}
.flaticon-dollar:before {
	content: $font-Flaticon-dollar;
}
.flaticon-network:before {
	content: $font-Flaticon-network;
}
.flaticon-business:before {
	content: $font-Flaticon-business;
}